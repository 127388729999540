<template>
  <div
    class="row g-6"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    "
  >
    <div
      class="col-xl-4"
      style="margin-top: 6%; margin-bottom: 20%; margin-right: 20%"
    >
      <div class="card" style="width: 700px">
        <div class="card-body d-flex flex-column">
          <div class="text-center">
            <div class="card-label fw-bolder fs-2">{{ $t("modals.interview.startInterview.title") }}</div>
            <span class="text-muted fs-7 mb-2"
              >{{ $t("modals.interview.startInterview.desc") }}</span
            >
          </div>
          <Form
            id="kt_account_profile_details_form"
            class="form"
            :validation-schema="addForm"
            @submit="submit"
          >
            <div class="card-body p-9 ps-6 pb-1">
              <div class="row mb-0">
                <div class="col-md-6">
                  <div class="fv-row mb-10">
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("modals.interview.startInterview.department") }}</label
                    >
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="department"
                      autocomplete="off"
                      :placeholder="$t('modals.interview.startInterview.enterDepartment')"   
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="department" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="fv-row mb-10">
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("modals.interview.startInterview.mission") }}</label
                    >
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="jobPosition"
                      autocomplete="off"
                      :placeholder="$t('modals.interview.startInterview.enterMission')"   
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="jobPosition" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-12">
                  <div class="fv-row mb-10">
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("modals.interview.startInterview.reason") }}</label
                    >
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="reason"
                      autocomplete="off"
                      :placeholder="$t('modals.interview.startInterview.enterReason')"   
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="reason" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-12">
                  <div class="fv-row mb-10">
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("modals.interview.startInterview.experience") }}</label
                    >
                    <div class="form-check">
                      <Field
                        type="radio"
                        class="form-check-input"
                        name="experience"
                        value="Yeni Mezunum"
                      />
                      <label class="form-check-label">{{ $t("modals.interview.startInterview.graduate") }}</label>
                    </div>
                    <div class="form-check">
                      <Field
                        type="radio"
                        class="form-check-input"
                        name="experience"
                        value="0-1 Yıl"
                      />
                      <label class="form-check-label">{{ $t("modals.interview.startInterview.oneYear") }}</label>
                    </div>
                    <div class="form-check">
                      <Field
                        type="radio"
                        class="form-check-input"
                        name="experience"
                        value="2-3 Yıl"
                      />
                      <label class="form-check-label">{{ $t("modals.interview.startInterview.twoYears") }}</label>
                    </div>
                    <div class="form-check">
                      <Field
                        type="radio"
                        class="form-check-input"
                        name="experience"
                        value="4-5 Yıl"
                      />
                      <label class="form-check-label">{{ $t("modals.interview.startInterview.fourYears") }}</label>
                    </div>
                    <div class="form-check">
                      <Field
                        type="radio"
                        class="form-check-input"
                        name="experience"
                        value="6 Yıl ve üstü"
                      />
                      <label class="form-check-label">{{ $t("modals.interview.startInterview.moreSixYears") }}</label>
                    </div>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="experience" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <!--begin::Submit button-->
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                <span class="indicator-label">{{ $t("common.button.start") }}</span>
                    <span class="indicator-progress">
                      {{ $t("common.pleaseWait") }}
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                </button>
                <!--end::Submit button-->
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "start-interview",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    //Create form validation object
    const addForm = Yup.object().shape({
      department: Yup.string()
        .required(t("validators_error.required"))
        .label("Department"),
      jobPosition: Yup.string()
        .required(t("validators_error.required"))
        .label("JobPosition"),
      reason: Yup.string()
        .required(t("validators_error.required"))
        .label("Reason"),
      experience: Yup.string()
        .required(t("validators_error.required"))
        .label("Experience"),
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.startInterview.title"));
    });

    const submit = (values: any) => {
      console.log(values);
      router.push({ path: `/interviewUser` });
    };

    return {
      addForm,
      submit
    };
  },
});
</script>
